<template>
  <v-footer class="red02-bg footer" :style="{'background':'url('+cover02+')'}">
    <v-container>
      <v-row class="py-5">
        <v-col class="col-12 col-sm-6 col-md-4 py-6">
          <img :src="logo" alt="LOGO DISATEL (BLANCO)" width="175" aspect-ratio="1" />
          <div class="mt-4">
            <v-btn
              :href="item.link"
              icon
              v-for="(item, index) in social"
              :key="index"
              class="mr-4"
              target="”_blank”"
            >
              <v-avatar
                class="mr-1"
                :class="item.estado ? 'shadow-3' : '' "
                :color="item.estado ? 'white' : 'social-buttom-black-bg' "
                size="40"
                @mouseover="item.estado = true"
                @mouseleave="item.estado = false"
              >
                <v-img :src="item.estado ? item.icono_hover : item.icono"></v-img>
              </v-avatar>
            </v-btn>
          </div>
        </v-col>
        <v-col class="col-12 col-sm-6 col-md-5 py-6">
          <h3 class="white--text raleway-bold size-3-1">Información de Contacto</h3>
          <p class="raleway-medium white--text size-4 pt-3">Nuestro personal de monitoreo está disponible las 24 horas, para apoyarle los 7 dias de la semana.</p>
          <div class="py-3">
            <v-expansion-panels flat accordion hover focusable dark>
              <v-expansion-panel
                v-for="(item, index) in contacto"
                :key="index"
                style="background-color: transparent!important"
              >
                <v-expansion-panel-header>{{item.filial}}</v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list dense flat class="transparent-bg">
                    <v-list-item
                      v-for="(item, index) in item.contacto"
                      :key="index"
                      :href="item.link"
                    >
                      <v-list-item-icon class="mr-0">
                        <v-img :src="item.icono" aspect-ratio="1" width="21" />
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.valor"
                          class="raleway-medium white--text pl-5 size-4"
                        ></v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
      <v-divider></v-divider>
      <div>
        <v-col
          class="mt-3 white--text raleway-medium white--text pl-0"
          cols="12"
        >Copyrights © 2025 - Grupo Disatel | Todos los derechos reservados</v-col>
      </div>
    </v-container>
  </v-footer>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {
      cover02: require("@/assets/texture01.png"),
      logo: require("@/assets/LogoDisatel_white.svg"),
      social: [
        {
          estado: false,
          titulo: "Facebook",
          icono: require("@/assets/facebook_white.svg"),
          icono_hover: require("@/assets/facebook.svg"),
          link: "https://www.facebook.com/people/Grupo-Disatel/100064077430893/"
        },
        {
          estado: false,
          titulo: "Instagram",
          icono: require("@/assets/instagram_white.svg"),
          icono_hover: require("@/assets/instagram.svg"),
          link: "https://www.instagram.com/grupodisatel/"
        },
        {
          estado: false,
          titulo: "LinkedIn",
          icono: require("@/assets/linkdin_white.svg"),
          icono_hover: require("@/assets/linkdin.svg"),
          link: "https://www.linkedin.com/company/grupo-disatel/"
        }
      ],
      contacto: [
        {
          filial: "Guatemala",
          contacto: [
            {
              icono: require("@/assets/phone.svg"),
              valor: "(+502) 2427-3400",
              link: "tel:(+502) 2427-3400"
            },
            {
              icono: require("@/assets/mail.svg"),
              valor: "info.gt@grupodisatel.com",
              link: ""
            },
            {
              icono: require("@/assets/time.svg"),
              valor: "Lunes a Viernes: 08:00 - 18:00",
              link: ""
            }
            ,
            {
              icono: null,
              valor: "Sábado: 08:00 - 12:00",
              link: ""
            }
          ]
        },
        /*{
          filial: "México",
          contacto: [
            {
              icono: require("@/assets/phone.svg"),
              valor: "(+52) 55 5522-2160",
              link: "tel:(+52) 55 5522-2160"
            },
            {
              icono: require("@/assets/mail.svg"),
              valor: "info.mx@grupodisatel.com",
              link: ""
            }
          ]
        },*/
        {
          filial: "El Salvador",
          contacto: [
            {
              icono: require("@/assets/phone.svg"),
              valor: "(+503) 2522-1400",
              link: "tel:(+503) 2522-1400"
            },
            {
              icono: require("@/assets/mail.svg"),
              valor: "info.sv@grupodisatel.com",
              link: ""
            },
            {
              icono: require("@/assets/time.svg"),
              valor: "Lunes a Viernes: 08:00 - 17:00",
              link: ""
            }
          ]
        },
        {
          filial: "Honduras",
          contacto: [
            {
              icono: require("@/assets/phone.svg"),
              valor: "(+504) 3368-9728",
              link: "tel:(+504) 3368-9728"
            },
            {
              icono: require("@/assets/mail.svg"),
              valor: "info.hn@grupodisatel.com",
              link: ""
            },
            {
              icono: require("@/assets/time.svg"),
              valor: "Lunes a Viernes: 08:00 - 17:00",
              link: ""
            }
          ]
        },
        {
          filial: "Nicaragua",
          contacto: [
            {
              icono: require("@/assets/phone.svg"),
              valor: "(+505) 2252-4330",
              link: "tel:(+505) 2252-4330"
            },
            {
              icono: require("@/assets/mail.svg"),
              valor: "info.ni@grupodisatel.com",
              link: ""
            },
            {
              icono: require("@/assets/time.svg"),
              valor: "Lunes a Viernes: 08:00 - 17:00",
              link: ""
            }
          ]
        },
        {
          filial: "Costa Rica",
          contacto: [
            {
              icono: require("@/assets/phone.svg"),
              valor: "(+506) 7172-2641",
              link: "tel:(+506) 7172-2641"
            },
            {
              icono: require("@/assets/mail.svg"),
              valor: "info.cr@grupodisatel.com",
              link: ""
            },
            {
              icono: require("@/assets/time.svg"),
              valor: "Lunes a Viernes: 08:00 - 17:00",
              link: ""
            }
          ]
        },
        {
          filial: "Panamá",
          contacto: [
            {
              icono: require("@/assets/phone.svg"),
              valor: "(+507) 387-0315",
              link: "tel:(+507) 387-0315"
            },
            {
              icono: require("@/assets/mail.svg"),
              valor: "info.pa@grupodisatel.com",
              link: ""
            },
            {
              icono: require("@/assets/time.svg"),
              valor: "Lunes a Viernes: 08:00 - 17:00",
              link: ""
            }
          ]
        },
        {
          filial: "Bolivia",
          contacto: [
            {
              icono: require("@/assets/phone.svg"),
              valor: "(+591) 760-50080",
              link: "tel:(+591) 760-50080"
            },
            {
              icono: null,
              valor: "(+591) (3) 357-9555",
              link: "tel:(+591) (3) 357-9555"
            },
            {
              icono: require("@/assets/mail.svg"),
              valor: "info.bo@grupodisatel.com",
              link: ""
            },
            {
              icono: require("@/assets/time.svg"),
              valor: "Lunes a Viernes: 8:30 a 12:30 y 14:30 a 18:30",
              link: ""
            },
            {
              icono: null,
              valor: "Sábado: 8:30 a 12:30",
              link: ""
            }
          ]
        }
      ],
    };
  }
};
</script>